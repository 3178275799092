import React from "react";
import Footer from "./Footer";
import "./Services.css";
import Underwriting from "../images/phoneUnderwriting.jpg";
import Claim from "../images/phoneClaim.jpg";
import Delivery from "../images/phoneDelivery.jpg";
import Consulting from "../images/phoneConsultancy.jpg";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";

function Services() {
  return (
    <div>
      <section className="services-display-page">
        <Link
          to="/underwriting"
          class="card"
          onClick={() => window.scrollTo(0, 0)}
        >
          <div class="card__img-container">
            <img class="card__img" src={Underwriting} alt="Underwriting" />
          </div>
          <div class="card__body">
            <h3 class="card__title">
              <Fade>Underwriting service</Fade>
            </h3>
            <p class="card__date">
              We provide expert underwriting services to ensure accurate risk
              evaluation for client investments.
            </p>
            <p class="card__cta">Read more</p>
          </div>
        </Link>
        <Link to="/claim" class="card" onClick={() => window.scrollTo(0, 0)}>
          <div class="card__img-container">
            <img class="card__img" src={Claim} alt="Claim" />
          </div>
          <div class="card__body">
            <h3 class="card__title">
              <Fade>Claim service</Fade>
            </h3>
            <p class="card__date">
              We help provide customers with timely and accurate claim services.
            </p>
            <p class="card__cta">Read more</p>
          </div>
        </Link>
        <Link
          to="/consulting"
          class="card"
          onClick={() => window.scrollTo(0, 0)}
        >
          <div class="card__img-container">
            <img class="card__img" src={Consulting} alt="Consulting" />
          </div>
          <div class="card__body">
            <h3 class="card__title">
              <Fade>Training and consultancy</Fade>
            </h3>
            <p class="card__date">
              We offer comprehensive training and consultancy services.
            </p>
            <p class="card__cta">Read more</p>
          </div>
        </Link>
        <Link to="/delivery" class="card" onClick={() => window.scrollTo(0, 0)}>
          <div class="card__img-container">
            <img class="card__img" src={Delivery} alt="Delivery" />
          </div>
          <div class="card__body">
            <h3 class="card__title">
              <Fade>Delivery</Fade>
            </h3>
            <p class="card__date">
              We will deliver your documnets where you are and on time.
            </p>
            <p class="card__cta">Read more</p>
          </div>
        </Link>
      </section>
      <Footer />
    </div>
  );
}

export default Services;
