import "./list2.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Datatable2 from "../../components/datatable2/Datatable2";

const List2 = () => {
  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Datatable2 />
      </div>
    </div>
  );
};

export default List2;
