import React from "react";
import "./Underwriting.css";
import Footer from "./Footer";
import Zoom from "react-reveal/Zoom";

function Underwriting() {
  return (
    <div>
      <head>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        />
        <script src="https://kit.fontawesome.com/a076d05399.js"></script>
      </head>
      <h1 className="underwriting-header">
        <Zoom>Underwriting</Zoom>
      </h1>
      <section class="uw-page" id="uw-page">
        <div class="uw-desc-box">
          <div class="uw-content">
            <p>
              <i class="fa fa-angle-right"></i> We do everything possible to
              satisfy the insurance requirements of our clients before all other
              considerations.
            </p>
            <p>
              <i class="fa fa-angle-right"></i> We examine the type of risks and
              chose the appropriate covers, obtain raw data from customers and
              fill out the complex proposal forms which insurers need in order
              to thoroughly assess the risk they are being asked to underwrite
            </p>
            <p>
              <i class="fa fa-angle-right"></i> We represent the client in the
              course of negotiating insurance cover and serve the client with
              reasonable professional skill in the course of dispensing advice
              on the insurance needs of the client.
            </p>
            <p>
              <i class="fa fa-angle-right"></i> Subject to an agreement with the
              insurer, we may collect premiums and issue cover notes.
            </p>
            <p>
              <i class="fa fa-angle-right"></i> We place the clients’ business
              with an insurance company/companies after providing the clients
              with detailed information concerning the insurance
              company/companies, the type(s) of cover, the cost, inception and
              expiry date of policy and such other information relating to the
              risk covered after securing the full consent of the client.
            </p>
            <p>
              <i class="fa fa-angle-right"></i> Where a customer is already
              insured we examine the type of cover, the scope of cover, and the
              price and make the right recommendation which will avoid dispute
              at the time of claims.
            </p>
            <p>
              <i class="fa fa-angle-right"></i> We do risk assessment, and
              suggest on the adequacy of sum insured, and liability limit.
            </p>
            <p>
              <i class="fa fa-angle-right"></i> We follow-up the preparation of
              policy/ renewal endorsement.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Underwriting;
