import "./datatable2.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

const Datatable2 = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let list = [];
      try {
        const today = new Date();
        const nextMonth = new Date(new Date().setMonth(today.getMonth() + 1));

        let prescription = {
          prescriptionDate: new Date(), // Today
          prescriptionExpirationDate: 20, // Days to add
        };

        let date = new Date(
          new Date(prescription.prescriptionDate).setDate(
            prescription.prescriptionDate.getDate() +
              prescription.prescriptionExpirationDate
          )
        );

        const usersRef = collection(db, "users");
        const nextMonthQuery = query(
          usersRef,
          where("endDate", ">=", date),
          where("endDate", "<=", nextMonth)
        );

        const nextMonthData = await getDocs(nextMonthQuery);

        nextMonthData.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setData(list);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  const userColumns = [
    {
      field: "id",
      headerName: "id",
      width: 150,
    },
    {
      field: "NameOfInsured",
      headerName: "Name of insured",
      width: 150,
    },
    {
      field: "InterestInsured",
      headerName: "Interest Insured",
      width: 150,
    },
    {
      field: "SumInsured",
      headerName: "Sum Insured",
      width: 150,
    },
    {
      field: "TypeOfCover",
      headerName: "Type of cover",
      width: 150,
    },
    {
      field: "PolicyNo",
      headerName: "Policy Number",
      width: 150,
    },
    {
      field: "PlateNo",
      headerName: "Plate Number",
      width: 150,
    },
    {
      field: "Insurer",
      headerName: "Insurer",
      width: 150,
    },
    {
      field: "startingDate",
      headerName: "From Date",
      width: 150,
    },
    {
      field: "endingDate",
      headerName: "To Date",
      width: 150,
    },
    {
      field: "Premium",
      headerName: "Premium",
      width: 150,
    },
    {
      field: "CommissionRate",
      headerName: "Commission Rate",
      width: 150,
    },
    {
      field: "CommissionAmount",
      headerName: "Commission Amount",
      width: 150,
    },
    {
      field: "Remark",
      headerName: "Remark",
      width: 150,
    },
  ];

  return (
    <div className="datatable">
      <div className="datatableTitle">
        Add new client data
        <Link to="/admin/users/new" className="link">
          Add New
        </Link>
      </div>
      <DataGrid
        className="datagrid"
        rows={data}
        columns={userColumns}
        columnVisibilityModel={{
          // Hide columns status and traderName, the other columns will remain visible
          id: false,
        }}
      />
    </div>
  );
};

export default Datatable2;
