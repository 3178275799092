export const userColumns = [
  {
    field: "id",
    headerName: "id",
    width: 150,
    editable: true,
  },
  {
    field: "NameOfInsured",
    headerName: "Name of insured",
    width: 150,
    editable: true,
  },
  {
    field: "InterestInsured",
    headerName: "Interest Insured",
    width: 150,
    editable: true,
  },
  {
    field: "SumInsured",
    headerName: "Sum Insured",
    width: 150,
    editable: true,
  },
  {
    field: "TypeOfCover",
    headerName: "Type of cover",
    width: 150,
    editable: true,
  },
  {
    field: "PolicyNo",
    headerName: "Policy Number",
    width: 150,
    editable: true,
  },
  {
    field: "PlateNo",
    headerName: "Plate Number",
    width: 150,
    editable: true,
  },
  {
    field: "Insurer",
    headerName: "Insurer",
    width: 150,
    editable: true,
  },
  {
    field: "startingDate",
    headerName: "From Date",
    width: 150,
    editable: true,
  },
  {
    field: "endingDate",
    headerName: "To Date",
    width: 150,
    editable: true,
  },
  {
    field: "Premium",
    headerName: "Premium",
    width: 150,
    editable: true,
  },
  {
    field: "CommissionRate",
    headerName: "Commission Rate",
    width: 150,
    editable: true,
  },
  {
    field: "CommissionAmount",
    headerName: "Commission Amount",
    width: 150,
    editable: true,
  },
  {
    field: "Remark",
    headerName: "Remark",
    width: 150,
    editable: true,
  },
];
