import "./widget.scss";
import { useEffect, useState } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const Widget = () => {
  const [ToBeContacted, setToBeContacted] = useState(0);
  const [TotalUsers, setTotalUsers] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const today = new Date();
      const nextMonth = new Date(new Date().setMonth(today.getMonth() + 1));

      let prescription = {
        prescriptionDate: new Date(), // Today
        prescriptionExpirationDate: 20, // Days to add
      };

      let date = new Date(
        new Date(prescription.prescriptionDate).setDate(
          prescription.prescriptionDate.getDate() +
            prescription.prescriptionExpirationDate
        )
      );

      const usersRef = collection(db, "users");
      const nextMonthQuery = query(
        usersRef,
        where("endDate", ">=", date),
        where("endDate", "<=", nextMonth)
      );

      const nextMonthData = await getDocs(nextMonthQuery);
      setToBeContacted(nextMonthData.docs.length);

      const users = collection(db, "users");
      const totlaUsers = await getDocs(users);
      setTotalUsers(totlaUsers.docs.length);
    };
    fetchData();
  }, []);

  return (
    <div className="widget">
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Total client data
          </Typography>
          <Typography variant="h5" component="div">
            {TotalUsers}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small">
            {" "}
            <Link to="/admin/users">See all data</Link>
          </Button>
        </CardActions>
      </Card>
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            To be contacted in advance
          </Typography>
          <Typography variant="h5" component="div">
            {ToBeContacted}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small">
            {" "}
            <Link to="/admin/notify">See all data</Link>
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};

export default Widget;
