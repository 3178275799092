import React from "react";
import "./Team.css";
import Musama from "../images/Musama.jpg";
import Tesfaye from "../images/Tesfaye.jpg";
import Eskindir from "../images/Eskindir.jpg";
import Betelihem from "../images/Betelihem.jpg";
import Dani from "../images/Dani.jpg";
import Estifanos from "../images/Estifanos.jpg";
import Footer from "./Footer";
import Button from "@mui/material/Button";
import Zoom from "react-reveal/Zoom";
import CallIcon from "@mui/icons-material/Call";

function Team() {
  return (
    <div>
      <h1 className="team-h1">
        <Zoom>Our Team</Zoom>
      </h1>

      <section>
        <div class="gallery-image">
          <div class="img-box">
            <img src={Musama} alt="" />
            <div class="transparent-box">
              <div class="caption">
                <p>Musema Husein</p>
                <p class="opacity-low">Marketing & Operation Manager</p>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  endIcon={<CallIcon />}
                  href="tel:+251911947003"
                >
                  Contact
                </Button>
              </div>
            </div>
          </div>
          <div class="img-box">
            <img src={Tesfaye} alt="" />
            <div class="transparent-box">
              <div class="caption">
                <p>Tesfaye Mehammed</p>
                <p class="opacity-low">Managing Director</p>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  endIcon={<CallIcon />}
                  href="tel:+251930098745"
                >
                  Contact
                </Button>
              </div>
            </div>
          </div>
          <div class="img-box">
            <img src={Eskindir} alt="" />
            <div class="transparent-box">
              <div class="caption">
                <p>Eskinder Assefa </p>
                <p class="opacity-low">Deputy Managing Director</p>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  endIcon={<CallIcon />}
                  href="tel:+251911290206"
                >
                  Contact
                </Button>
              </div>
            </div>
          </div>
          <div class="img-box">
            <img src={Betelihem} alt="" />
            <div class="transparent-box">
              <div class="caption">
                <p>Betelhem Girma</p>
                <p class="opacity-low">Finance & Administration Manager</p>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  endIcon={<CallIcon />}
                  href="tel:+251912637003"
                >
                  Contact
                </Button>
              </div>
            </div>
          </div>
          <div class="img-box">
            <img src={Dani} alt="" />
            <div class="transparent-box">
              <div class="caption">
                <p>Daniel Tesfaye</p>
                <p class="opacity-low">Data Analyst</p>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  endIcon={<CallIcon />}
                  href="tel:+251913046151"
                >
                  Contact
                </Button>
              </div>
            </div>
          </div>
          <div class="img-box">
            <img src={Estifanos} alt="" />
            <div class="transparent-box">
              <div class="caption">
                <p>Estifanos Ayenachew</p>
                <p class="opacity-low">Underrating & Sales Supervisor</p>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  endIcon={<CallIcon />}
                  href="tel:+251911290214"
                >
                  Contact
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Team;
