import React from "react";
import "./Delivery.css";
import Footer from "./Footer";
import Zoom from "react-reveal/Zoom";

function Delivery() {
  return (
    <div>
      <head>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        />
        <script src="https://kit.fontawesome.com/a076d05399.js"></script>
      </head>
      <h1 className="delivery-header">
        <Zoom>Delivery</Zoom>
      </h1>
      <section class="delivery-page" id="delivery-page">
        <div class="delivery-desc-box">
          <div class="delivery-content">
            <p>
              <i class="fa fa-angle-right"></i> We deliver Proposal forms,
              supporting documents and all other insurance related documents to
              the insurance companies.
            </p>
            <p>
              <i class="fa fa-angle-right"></i> We deliver payments like check,
              cash and bank deposit slips to insurance companies.
            </p>
            <p>
              <i class="fa fa-angle-right"></i> We deliver letters, insurance
              policies, certificates, renewal, inclusion, exclusion, mortgage
              and other amendment endorsements to our customers.
            </p>
            <p>
              <i class="fa fa-angle-right"></i> We deliver premium quotations
              collected from various insurers together with the analysis made by
              our brokerage to our customers.
            </p>
            <p>
              <i class="fa fa-angle-right"></i> We deliver the received renewal
              notices or reminders from insurance companies to our customers
              before 15 to 30 days of the expiry date.
            </p>
            <p>
              <i class="fa fa-angle-right"></i> We receive a police report
              request from the insurance company and give it to the client or
              the concerned client’s employee.
            </p>
            <p>
              <i class="fa fa-angle-right"></i> Receive the required documents
              from the customers and submit them to the insurance company to
              enable them process the claim.
            </p>
            <p>
              <i class="fa fa-angle-right"></i> Receive the repair or
              replacement cost of the damage from the customer or the garage for
              insurance companies.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Delivery;
