import React from "react";
import "./Consulting.css";
import Footer from "./Footer";
import Zoom from "react-reveal/Zoom";

export default function Consulting() {
  return (
    <div>
      <head>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        />
        <script src="https://kit.fontawesome.com/a076d05399.js"></script>
      </head>
      <h1 className="consulting-header">
        <Zoom>Training and Consultancy</Zoom>
      </h1>
      <section class="consulting-page" id="consulting-page">
        <div class="consulting-desc-box">
          <div class="consulting-content">
            <p>
              A&#41; Provide training to clients on the basic principles and
              practice of insurance
            </p>
            <p>
              B&#41; Create awareness on the main stream of commercial insurance
              products and micro insurance cover. This among other things
              includes:
              <p>
                <i class="fa fa-angle-right"></i> Creation of understanding on
                relative costs of the principal types of insurance to the client
                and provide advice on the types of insurance cover that best
                suit the client’s needs
              </p>
              <p>
                <i class="fa fa-angle-right"></i> Demonstration of different
                ways in which premium is calculated
              </p>
              <p>
                <i class="fa fa-angle-right"></i> Explain the structure,
                functions and contents of a policy exceptions and conditions
                i.e. how excesses and deductibles are used; warranties, how
                cancellation clauses operate… etc
              </p>
              <p>
                <i class="fa fa-angle-right"></i> Trainings to the clients,
                staffs on insurance related matters.
              </p>
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
