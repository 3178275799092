import React from "react";
import "./Claim.css";
import Footer from "./Footer";
import Zoom from "react-reveal/Zoom";

function Claim() {
  return (
    <div>
      <head>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        />
        <script src="https://kit.fontawesome.com/a076d05399.js"></script>
      </head>
      <h1 className="claim-header">
        <Zoom>Claim</Zoom>
      </h1>
      <section class="claim-page" id="claim-page">
        <div class="claim-desc-box">
          <div class="claim-content">
            <p>
              <i class="fa fa-angle-right"></i> Our customers can contact us
              7/24 in case of accidents.
            </p>
            <p>
              <i class="fa fa-angle-right"></i> In the event of an accident, we
              receive information about the nature of the accident from someone
              close to the accident or the one who has an information about the
              accident.
            </p>
            <p>
              <i class="fa fa-angle-right"></i> We notify the insurance
              companies with our own temporary claim notification form
              immediately.
            </p>
            <p>
              <i class="fa fa-angle-right"></i> We fill the final notification
              form with the customers’ concerned person.
            </p>
            <p>
              <i class="fa fa-angle-right"></i> Show the damaged property to an
              insurance company’s inspector and surveyor.
            </p>
            <p>
              <i class="fa fa-angle-right"></i> Collect police reports for
              vehicles driven by the higher officials and department heads where
              the places of accidents are with in Addis Ababa.
            </p>
            <p>
              <i class="fa fa-angle-right"></i> Assist in selection of garages
              and collection of bid offers.
            </p>
            <p>
              <i class="fa fa-angle-right"></i> Receive the damage details from
              the insurers and give it to the customer, garage or spare parts
              store and submit the Pro forma invoices to the insurers.
            </p>
            <p>
              <i class="fa fa-angle-right"></i> Follow up & get accomplished
              letters and quotations submitted to insurance companies regarding
              our clients issue as soon as possible.
            </p>
            <p>
              <i class="fa fa-angle-right"></i> Follow up the claim up-to its
              settlement.
            </p>
            <p>
              <i class="fa fa-angle-right"></i> Inform the customer about the
              status of the claim with a regular report.
            </p>
            <p>
              <i class="fa fa-angle-right"></i> Set our claims service level
              standards by which our customer claims are going to be
              entertained.
            </p>
            <p>
              <i class="fa fa-angle-right"></i> Assist clients in lodging of
              claims and advice on pertinent documents to substantiate claims.
            </p>
            <p>
              <i class="fa fa-angle-right"></i> Assist clients during
              negotiation of claims.
            </p>
            <p>
              <i class="fa fa-angle-right"></i> Explain the price advantage
              in-order to not making small claims.
            </p>
            <p>
              <i class="fa fa-angle-right"></i> Closely follow up compliance
              with policy provisions and advice the clients about policy
              conditions pertinent to claims.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Claim;
