import React from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import Team from "../images/Team.jpg";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import Claim from "@mui/icons-material/VolunteerActivism";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Zoom from "react-reveal/Zoom";
import ContactUs from "./ContactUs";

export default function Home() {
  return (
    <div>
      <section class="banner" id="home">
        <div class="banner-text">
          <Fade bottom>
            <h1>Meri Insurance Broker S.C</h1>
          </Fade>
          <Fade>
            <p>
              Making sure you're covered - with the peace of mind you deserve.
            </p>
            <Link to="/about">
              <span className="hover-arrow">Learn More</span>{" "}
              <i class="fa fa-arrow-right"></i>
            </Link>
          </Fade>
        </div>
      </section>
      <div className="rest-page">
        <section id="about" className="about">
          <div className="row">
            <div className="image">
              <img src={Team} alt="Why choose us?" />
              <div class="team-transparent-box">
                <div class="team-caption">
                  <p>
                    Meri Insurance Broker Share company Share Holders &
                    Management Staff Members
                  </p>
                </div>
              </div>
            </div>
            <div className="content">
              <Fade bottom>
                <h3>Why choose us?</h3>
              </Fade>

              <p>
                Meri Insurance Broker is an industry-leading insurance brokerage
                that offers a comprehensive portfolio of services. With its
                years of experience, extensive network and access to top-notch
                providers, Meri Insurance Brokerage can help you find the
                perfect coverage to meet your needs. Meri’s staff is
                knowledgeable and helpful, which makes the process of finding
                insurance simple and stress-free. If you're looking for quality
                insurance coverage at an affordable price point, Meri Insurance
                Brokerage should be your first choice.
              </p>

              <Fade>
                <div className="button-container">
                  <Link to="/about" onClick={() => window.scrollTo(0, 0)}>
                    <button class="home-btn home-btn1">read more</button>
                  </Link>
                  <Link to="/contact-us" onClick={() => window.scrollTo(0, 0)}>
                    <button class="home-btn">get started</button>
                  </Link>
                </div>
              </Fade>
            </div>
          </div>
        </section>
        <section id="services" className="services">
          <Fade bottom>
            <h3 className="service-header">Our services</h3>
          </Fade>
          <div class="box-container">
            <Link
              to="/underwriting"
              className="service-link"
              onClick={() => window.scrollTo(0, 0)}
            >
              <div class="box">
                <Zoom>
                  <BorderColorIcon className="icons" sx={{ fontSize: 80 }} />
                </Zoom>
                <h3>Underwriting service</h3>
                <p>
                  We provide expert underwriting services to ensure accurate
                  risk evaluation for client investments.
                </p>
              </div>
            </Link>
            <Link
              to="/claim"
              className="service-link"
              onClick={() => window.scrollTo(0, 0)}
            >
              <div class="box">
                <Zoom>
                  <Claim className="icons" sx={{ fontSize: 80 }} />
                </Zoom>
                <h3>Claim service</h3>
                <p>
                  We help provide customers with timely and accurate claim
                  services.
                </p>
              </div>
            </Link>
            <Link
              to="/consulting"
              className="service-link"
              onClick={() => window.scrollTo(0, 0)}
            >
              <div class="box">
                <Zoom>
                  <SupervisorAccountIcon
                    className="icons"
                    sx={{ fontSize: 80 }}
                  />
                </Zoom>
                <h3>Training and consultancy service</h3>
                <p>We offer comprehensive training and consultancy services.</p>
              </div>
            </Link>
            <Link
              to="/delivery"
              className="service-link"
              onClick={() => window.scrollTo(0, 0)}
            >
              <div class="box">
                <Zoom>
                  <LocalShippingIcon className="icons" sx={{ fontSize: 80 }} />
                </Zoom>
                <h3>Delivery</h3>
                <p>We will deliver your documnets where you are and on time.</p>
              </div>
            </Link>
          </div>
        </section>
        <ContactUs />
      </div>
    </div>
  );
}
