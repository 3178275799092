import AdminHome from "./pages/home/Home";
import Login from "./pages/login/Login";
import List from "./pages/list/List";
import List2 from "./pages/list2/List2";
import Single from "./pages/single/Single";
import New from "./pages/new/New";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { productInputs, userInputs } from "./formSource";
import "./style/dark.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import Navbar from "./components/Navbar";
import "./App.css";
import Home from "./components/pages/Home";
import Services from "./components/pages/Services";
import About from "./components/pages/About";
import ContactUs from "./components/pages/ContactUs";
import SignUp from "./components/pages/SignUp";
import Underwriting from "./components/pages/Underwriting";
import Consulting from "./components/pages/Consulting";
import Claim from "./components/pages/Claim";
import Delivery from "./components/pages/Delivery";
import Team from "./components/pages/Team";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "./context/AuthContext";

function App() {
  const { darkMode } = useContext(DarkModeContext);

  const { currentUser } = useContext(AuthContext);

  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/admin/login" />;
  };

  return (
    <div>
      <div className={darkMode ? "app dark" : "app"}>
        <BrowserRouter>
          <ToastContainer />
          <Navbar />
          <Routes>
            <Route path="/" index element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/underwriting" element={<Underwriting />} />
            <Route path="/consulting" element={<Consulting />} />
            <Route path="/claim" element={<Claim />} />
            <Route path="/delivery" element={<Delivery />} />
            <Route path="/team" element={<Team />} />
            <Route path="/admin">
              <Route path="/admin/login" element={<Login />} />
              <Route
                index
                element={
                  <RequireAuth>
                    <AdminHome />
                  </RequireAuth>
                }
              />
              <Route path="/admin/users">
                <Route
                  index
                  element={
                    <RequireAuth>
                      <List />
                    </RequireAuth>
                  }
                />
                <Route
                  path=":userId"
                  element={
                    <RequireAuth>
                      <Single />
                    </RequireAuth>
                  }
                />
                <Route
                  path="new"
                  element={
                    <RequireAuth>
                      <New inputs={userInputs} title="Add new client data" />
                    </RequireAuth>
                  }
                />
              </Route>
              <Route path="/admin/notify">
                <Route
                  index
                  element={
                    <RequireAuth>
                      <List2 />
                    </RequireAuth>
                  }
                />
                <Route
                  path="new"
                  element={
                    <RequireAuth>
                      <New inputs={productInputs} title="Add New Product" />
                    </RequireAuth>
                  }
                />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
