import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "react-rainbow-components";
import Sending from "./Sending.svg";

const New = () => {
  const [data, setData] = useState({});
  const [bool, setBool] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const navigate = useNavigate();

  const startingDate = startDate.toLocaleString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });

  const endingDate = endDate.toLocaleString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });

  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    setData({
      ...data,
      [id]: value,
    });
  };

  const handleAdd = async (e) => {
    try {
      e.preventDefault();
      await addDoc(
        collection(db, "users"),
        {
          ...data,
          startingDate,
          endingDate,
          endDate,
        },
        setBool(true)
      );
      navigate(-1);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  };
  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <div className="bottom">
          <div className="right">
            <form onSubmit={handleAdd}>
              <div className="formInput" key="NameOfInsured">
                <label>Name of insured</label>
                <input
                  id="NameOfInsured"
                  type="text"
                  onChange={handleInput}
                  required
                />
              </div>
              <div className="formInput" key="InterestInsured">
                <label>Interest insured</label>
                <input
                  id="InterestInsured"
                  type="text"
                  onChange={handleInput}
                  required
                />
              </div>
              <div className="formInput" key="SumInsured">
                <label>Sum insured</label>
                <input
                  id="SumInsured"
                  type="text"
                  onChange={handleInput}
                  required
                />
              </div>
              <div className="formInput" key="TypeOfCover">
                <label>Type of cover</label>
                <input
                  id="TypeOfCover"
                  type="text"
                  onChange={handleInput}
                  required
                />
              </div>
              <div className="formInput" key="PolicyNo">
                <label>Policy Number</label>
                <input
                  id="PolicyNo"
                  type="text"
                  onChange={handleInput}
                  required
                />
              </div>
              <div className="formInput" key="PlateNo">
                <label>Plate Number</label>
                <input
                  id="PlateNo"
                  type="text"
                  onChange={handleInput}
                  required
                />
              </div>
              <div className="formInput" key="Insurer">
                <label>Insurer</label>
                <input
                  id="Insurer"
                  type="text"
                  onChange={handleInput}
                  required
                />
              </div>
              <div className="formInput" key="PeriodFrom">
                <label>Period of insurance (From)</label> 
                <DatePicker
                  id="PeriodFrom"
                  value={startDate}
                  onChange={(date) => setStartDate(date)}
                  required
                />
              </div>
              <div className="formInput" key="Premium">
                <label>Premium</label>
                <input
                  id="Premium"
                  type="text"
                  onChange={handleInput}
                  required
                />
              </div>
              <div className="formInput" key="PeriodTo">
                <label>Period of insurance (To)</label>  
                <DatePicker
                  id="PeriodTo"
                  value={endDate}
                  onChange={(date) => setEndDate(date)}
                  required
                />
              </div>
              <div className="formInput" key="CommissionRate">
                <label>Commission rate</label>
                <input
                  id="CommissionRate"
                  type="text"
                  onChange={handleInput}
                  required
                />
              </div>
              <div className="formInput" key="CommissionAmount">
                <label>Commission Amount</label>
                <input
                  id="CommissionAmount"
                  type="text"
                  onChange={handleInput}
                  required
                />
              </div>
              <div className="formInput" key="Remark">
                <label>Remark</label>
                <input
                  id="Remark"
                  type="text"
                  onChange={handleInput}
                  required
                />
              </div>
              {bool ? (
                <img src={Sending} alt="Sending" className="sending-image" />
              ) : (
                <button type="submit">Send</button>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default New;
