import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "meri-database.firebaseapp.com",
  projectId: "meri-database",
  storageBucket: "meri-database.appspot.com",
  messagingSenderId: "466788152779",
  appId: "1:466788152779:web:45bc67a87e7cc95c77b24e",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
export const storage = getStorage(app);
