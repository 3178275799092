import React from "react";
import "./Footer.css";
import Logo from "../images/logo.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div>
      <head>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        />
        <script src="https://kit.fontawesome.com/a076d05399.js"></script>
      </head>
      <footer>
        <div class="content">
          <div class="top">
            <div class="logo-details">
              <Link
                to="/"
                className="navbar-logo"
                onClick={() => window.scrollTo(0, 0)}
              >
                <img className="logo" alt="logo" src={Logo} />
              </Link>
            </div>
            <div class="media-icons">
              <a href="/about">
                <i class="fa fa-telegram"></i>
              </a>
              <a href="https://www.linkedin.com/in/meri-insurance-broker-262982234/">
                <i class="fa fa-linkedin-square"></i>
              </a>
              <a href="mailto:meri.insurancebroker@gmail.com">
                <i class="fa fa-envelope"></i>
              </a>
            </div>
          </div>
          <div class="link-boxes">
            <ul class="box">
              <li class="link_name">Company</li>
              <li>
                <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about" onClick={() => window.scrollTo(0, 0)}>
                  About us
                </Link>
              </li>
              <li>
                <Link to="/team" onClick={() => window.scrollTo(0, 0)}>
                  Team
                </Link>
              </li>
              <li>
                <Link to="/contact-us" onClick={() => window.scrollTo(0, 0)}>
                  Contact us
                </Link>
              </li>
            </ul>
            <ul class="box">
              <li class="link_name">Services</li>
              <li>
                <Link to="/underwriting" onClick={() => window.scrollTo(0, 0)}>
                  Underwriting
                </Link>
              </li>
              <li>
                <Link to="/claim" onClick={() => window.scrollTo(0, 0)}>
                  Claim
                </Link>
              </li>
              <li>
                <Link to="/consulting" onClick={() => window.scrollTo(0, 0)}>
                  Training and consultancy
                </Link>
              </li>
              <li>
                <Link to="/delivery" onClick={() => window.scrollTo(0, 0)}>
                  Delivery
                </Link>
              </li>
            </ul>
            <ul class="box">
              <li class="link_name">Telephone</li>
              <li>
                <a href="tel:+251115576949">Tel +251115576949</a>
              </li>
              <li>
                <a href="tel:+251114707544">Tel +251-114707544</a>
              </li>
              <li>
                <a href="tel:+251115577003">Fax +251-115577003</a>
              </li>
              <li>
                <a href="tel:+251911519392">Mob +251-911519392</a>
              </li>
              <li>
                <a href="tel:+251911290206">Mob +251-911290206</a>
              </li>
            </ul>
            <ul class="box">
              <li class="link_name">Email</li>
              <li>
                <a href="mailto:meri.insurancebroker@gmail.com">
                  meri.insurancebroker <br />
                  @gmail.com
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="bottom-details">
          <div class="bottom_text">
            <span class="copyright_text">
              Copyright © 2022 <a href="/">Meri.</a>All rights reserved <br />{" "}
              Developed by{" "}
              <a href="mailto:michaelabebe19922000@gmail.com">
                {" "}
                Mike's Web Services
              </a>
            </span>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
