import React from "react";
import "./About.css";
import Footer from "./Footer";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";

function About() {
  return (
    <div>
      <h1 className="about-h1">
        <Zoom>About us</Zoom>
      </h1>

      <section class="about-page" id="about-page">
        <div class="desc-box">
          <div class="content">
            <Fade bottom>
              <h3>Who are we?</h3>
            </Fade>
            <p>
              <b>MERI INSURANCE BROKER</b> is a share company working with all
              insurance companies in the country which was established in 2012
              G.C to engage in insurance broking business. It was founded by
              highly qualified and well experienced shareholders in banking,
              insurance, accounting, financial management, engineering and law.
              The company’s leadership consists of reputed individuals
              acknowledged for their highest level professional standard, ethics
              and integrity. We are pleased to cordially invite your respected
              organization to establish lasting relationship to use the package
              of our risk management underwriting and claim service. We are
              equally working with each insurance company in the industry.{" "}
              <br />
              <br />
              This will help us to come up with a wide selection of Premium and
              quality service for your company. We sustain our professional
              service for you through business and personal integration with the
              staffs of each insurance company.
            </p>
            <Fade bottom>
              <h3>Our vision</h3>
            </Fade>
            <p>• To be the most preferred and ideal insurance mediator .</p>
            <Fade bottom>
              <h3>Our mission</h3>
            </Fade>
            <p>
              • Providing a dependable service playing an exceptional
              intermediary role with ethical and professional enthusiasm for the
              best of customers satisfaction through excelling marketing and
              consultancy service <br />• To render professional services to the
              insurance buyers with best standards of professional principles
              and thereby generate optimal profit.
            </p>
            <Fade bottom>
              <h3 className="download-header">Download documents</h3>
            </Fade>
            <a href="documents.pdf" download="documents">
              <Button variant="contained" startIcon={<DownloadIcon />}>
                Download 1
              </Button>
            </a>
            <a href="documents2.pdf" download="documents2">
              <Button variant="contained" startIcon={<DownloadIcon />}>
                Download 2
              </Button>
            </a>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default About;
