export const MenuItems = [
  {
    title: "Underwriting",
    path: "/underwriting",
    cName: "dropdown-link",
  },
  {
    title: "Claim",
    path: "/claim",
    cName: "dropdown-link",
  },
  {
    title: "Training & consultancy",
    path: "/consulting",
    cName: "dropdown-link",
  },
  {
    title: "Delivery",
    path: "/delivery",
    cName: "dropdown-link",
  },
];
