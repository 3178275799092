import React, { useState } from "react";
import Footer from "./Footer";
import Pulse from "react-reveal/Pulse";
import { Fade } from "react-reveal";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { toast } from "react-toastify";
import { useRef } from "react";
import load1 from "../images/loading.gif";

import "./ContactUs.css";

export default function ContactUs() {
  const [bool, setBool] = useState(false);

  const schema = yup.object().shape({
    fullName: yup.string().required("Your full name is required"),
    email: yup.string().email().required("Your email is required"),
    message: yup.string().min(5).max(300).required("Your message is required"),
  });

  const {
    register,
    resetField,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const ref = useRef(null);

  const onSubmit = async (e) => {
    try {
      let name = e.fullName;
      let email = e.email;
      let message = e.message;
      let data = {
        name,
        email,
        message,
      };
      setBool(true);
      const res = await axios.post(`${process.env.REACT_APP_POST_API}`, data);
      if (name.length === 0 || email.length === 0 || message.length === 0) {
        toast.error(res.data.msg);
        setBool(false);
      } else if (res.status === 200) {
        toast.success(res.data.msg);
        setBool(false);
        resetField("fullName");
        resetField("email");
        resetField("message");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <head>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        />
        <script src="https://kit.fontawesome.com/a076d05399.js"></script>
      </head>
      <section id="contact-us" className="contact-us">
        <Fade bottom>
          <h3 class="heading">Contact us</h3>
        </Fade>
        <div className="row">
          <div className="contact-info">
            <Fade left>
              <div className="box">
                <h3>
                  {" "}
                  <i class="fa fa-map-marker"></i>&nbsp;address{" "}
                </h3>
                <p>
                  Flamingo Area ESEMACO Bldg, <br />
                  3rdfloor, <br />
                  office #302 , 320 and 001.
                </p>
              </div>
            </Fade>
            <Fade left>
              <div className="box">
                <h3>
                  {" "}
                  <i class="fa fa-envelope-o"></i>&nbsp;e-mail{" "}
                </h3>
                <a href="mailto:meri.insurancebroker@gmail.com">
                  <p>meri.insurancebroker@gmail.com</p>
                </a>
              </div>
            </Fade>
            <Fade left>
              <div className="box">
                <h3>
                  {" "}
                  <i class="fa fa-phone"></i>&nbsp;phone{" "}
                </h3>
                <a href="tel:+251115576949">
                  <p>Tel +251-115576949</p>
                </a>
                <a href="tel:+251114707544">
                  <p>Tel +251-114707544</p>
                </a>
                <a href="tel:+251115577003">
                  <p>Fax +251-115577003</p>
                </a>
                <a href="tel:+251911519392">
                  <p>Mob +251-911519392</p>
                </a>
                <a href="tel:+251911290206">
                  <p>Mob +251-911290206</p>
                </a>
              </div>
            </Fade>
          </div>
          <div class="contact-form-container">
            <form onSubmit={handleSubmit(onSubmit)}>
              <h3>get in touch</h3>

              <div class="inputBox">
                <div className="input-alignmnet">
                  <input
                    ref={ref}
                    className="frm-txt"
                    type="text"
                    placeholder="full name"
                    {...register("fullName")}
                  />
                  <p>{errors.fullName?.message}</p>
                </div>
                <div className="input-alignmnet">
                  <input
                    className="frm-email"
                    type="email"
                    placeholder="e-mail"
                    {...register("email")}
                  />
                  <p>{errors.email?.message}</p>
                </div>
              </div>
              <div className="btn-alignment">
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="8"
                  placeholder="message"
                  {...register("message")}
                ></textarea>
                <p>{errors.message?.message}</p>
                <Pulse>
                  {bool ? (
                    <img src={load1} alt="loading" className="loading-image" />
                  ) : (
                    <input type="submit" value="message" />
                  )}
                </Pulse>
              </div>
            </form>
          </div>
        </div>
      </section>
      <Footer />
     
    </div>
  );
}
