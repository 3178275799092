import React from "react";
import "./Button.css";
import { Link } from "react-router-dom";

export function Button() {
  return (
    <div>
      <head>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        />
        <script src="https://kit.fontawesome.com/a076d05399.js"></script>
      </head>
      <Link to="/admin">
        <button className="btn">
          <i class="fa fa-user-circle-o" aria-hidden="true"></i> Login
        </button>
      </Link>
    </div>
  );
}
