import "./datatable.scss";
import {
  DataGrid,
  GridToolbarExport,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { userColumns } from "../../datatablesource";
import { Link } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import {
  collection,
  doc,
  deleteDoc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const Datatable = () => {
  const [data, setData] = useState([]);
  const [adminId, setAdminId] = useState("");
  const [editRowsModel, setEditRowsModel] = useState({});
  const [editRowData, setEditRowData] = useState({});

  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      const uid = user.email;
      // ...
      setAdminId(uid);
    } else {
      // User is signed out
      // ...
    }
  });

  useEffect(() => {
    const unsub = onSnapshot(
      collection(db, "users"),
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setData(list);
      },
      (error) => {
        console.log(error);
      }
    );
    return () => {
      unsub();
    };
  }, []);

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, "users", id));
      setData(data.filter((item) => item.id !== id));
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdate = async () => {
    const usersRef = doc(db, "users", editRowData.id.value);
    try {
      await updateDoc(usersRef, {
        NameOfInsured: editRowData.NameOfInsured.value,
        CommissionAmount: editRowData.CommissionAmount.value,
        CommissionRate: editRowData.CommissionRate.value,
        Insurer: editRowData.Insurer.value,
        InterestInsured: editRowData.InterestInsured.value,
        PlateNo: editRowData.PlateNo.value,
        PolicyNo: editRowData.PolicyNo.value,
        Premium: editRowData.Premium.value,
        Remark: editRowData.Remark.value,
        SumInsured: editRowData.SumInsured.value,
        TypeOfCover: editRowData.TypeOfCover.value,
        endingDate: editRowData.endingDate.value,
        startingDate: editRowData.startingDate.value,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  const actionColumn2 = [];

  function MyExportButton() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const handleEditRowsModelChange = useCallback(
    (model) => {
      const editedIds = Object.keys(model);

      // user stops editing when the edit model is empty
      if (editedIds.length === 0) {
        // update on firebase
        handleUpdate();
      } else {
        setEditRowData(model[editedIds[0]]);
      }

      setEditRowsModel(model);
    },
    [editRowData] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <div className="datatable">
      <div className="datatableTitle">
        Add new client data
        <Link to="/admin/users/new" className="link">
          Add New
        </Link>
      </div>
      {adminId === "musema@meri.com" ? (
        <DataGrid
          className="datagrid"
          rows={data}
          columns={userColumns.concat(actionColumn)}
          checkboxSelection
          disableRowSelectionOnClick
          components={{
            Toolbar: MyExportButton,
          }}
          editMode="row"
          editRowsModel={editRowsModel}
          onEditRowsModelChange={handleEditRowsModelChange}
          columnVisibilityModel={{
            // Hide columns status and traderName, the other columns will remain visible
            id: false,
          }}
        />
      ) : (
        <DataGrid
          className="datagrid"
          rows={data}
          columns={userColumns.concat(
            adminId === "musema@meri.com" || adminId === "dani@meri.com"
              ? actionColumn
              : actionColumn2
          )}
          editMode="row"
          editRowsModel={editRowsModel}
          onEditRowsModelChange={handleEditRowsModelChange}
          columnVisibilityModel={{
            // Hide columns status and traderName, the other columns will remain visible
            id: false,
          }}
        />
      )}
    </div>
  );
};

export default Datatable;
